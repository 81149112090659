<template>
  <v-app v-if="ready" class="overflow-hidden">
    <topmenubar :breadcrumbs="breadcrumbs" />
    <router-view />
  </v-app>
</template>

<script>
import topmenubar from "@/components/topmenubar.vue";
export default {
  components: { topmenubar },
  data() {
    return {
      ready: true,
      breadcrumbs: []
    };
  },
  mounted() {
    let path = this.$route.fullPath.split("/");
    this.breadcrumbs = [];
    let breadcrumbUrl = "/project";

    if (path.length > 0) {
      for (let index = 0; index <= path.length; index++) {
        if (index > 1) {
          breadcrumbUrl += "/" + path[index];
          if (path[index] != undefined) {
            let text = path[index].replace("-", " ");
            this.breadcrumbs.push({
              text: text.charAt(0).toUpperCase() + text.slice(1),
              disabled: index == path.length ? false : true,
              href: breadcrumbUrl,
            });
          }
        } else if (index == 1) {
          this.breadcrumbs.push({
            text: "Dashboard",
            disabled: false,
            href: "/",
          });
        }

      }
    }
  }
};
</script>
<style>
body {
  background: var(--themegreen);
}
.footer-buttons{
  width: fit-content !important;
  right: 0 !important;
  left: unset !important;
}
</style>