<template>
	<v-dialog v-model="dialog" content-class="theme-popup">
		<div>
			<div class="text-right">
				<v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
					<v-icon>fas fa-times </v-icon>
				</v-btn>
			</div>
			<div class="text-center pb-5">
				<h3>Collega's uitnodigen</h3>
			</div>

			<label for="">Type</label>
			<v-radio-group color="themepurple" row v-model="type">
				<v-radio label="Project" value="project"></v-radio>
				<v-radio label="Programma" value="program"></v-radio>
			</v-radio-group>

			<div v-if="type == 'project'">
				<label for=""> Kies een project waarvoor je mensen wilt uitnodigen </label>
				<v-select class="theme-input" solo :items="projects" v-model="dataproject.project" label="Selecteer een project" item-text="title" item-value="id"></v-select>
				<label for=""> E-mail ontvanger </label>
				<v-text-field class="theme-input" solo v-model="dataproject.useremail" title="E-mail ontvanger"></v-text-field>

				<label for="">Rol</label>
				<v-radio-group color="themepurple" row v-model="dataproject.role">
					<v-radio label="Project Manager" value="PM"></v-radio>
					<v-radio label="Project Uitvoerder" value="PU"></v-radio>
					<v-radio label="Organisator" value="OG"></v-radio>
				</v-radio-group>
			</div>
			<div v-else-if="type == 'program'">
				<label for=""> Kies een programma waarvoor je mensen wilt uitnodigen </label>
				<v-select class="theme-input" solo :items="programs" v-model="dataprogram.program" label="Selecteer een programma" item-text="title" item-value="id"></v-select>
				<label for=""> E-mail ontvanger </label>
				<v-text-field class="theme-input" solo v-model="dataprogram.useremail" title="E-mail ontvanger"></v-text-field>

				<label for="">Rol</label>
				<v-radio-group color="themepurple" row v-model="dataprogram.role">
					<v-radio label="Project Manager" value="PM"></v-radio>
					<v-radio label="Project Uitvoerder" value="PU"></v-radio>
					<v-radio label="Organisator" value="OG"></v-radio>
				</v-radio-group>
			</div>
		</div>
		<div class="text-center pt-5">
			<v-btn class="btn" @click="submit" color="themepurple" dark>Uitnodiging Versturen</v-btn>
		</div>
	</v-dialog>
</template>
<script>
import config from '../../../config';
import api from "../../../services/api";
export default {
	props: {
		dialog: Boolean,
	},
	data: () => ({
		projects: [],
    programs: [],
		type: "project",
		dataproject: {
			project: "",
			role: "",
			useremail: ""
		},
		dataprogram: {
			program: "",
			role: "",
			useremail: ""
		}
	}),
	methods: {
		submit() {
      if(this.type == 'project'){
        api.post(`${config.url.api}/v1/projects/create/invite`, this.dataproject).then((res) => {
          if (res.data.success) {
            //redirect to the newly created recource.
            this.$toast.success('Uitnodiging succesvol aangemaakt.')
            this.$emit('close-dialog');
          } else {
            this.$toast.error('Er ging iets mis bij het uitnodigen.')
          }
        }).catch(() => {
          this.$toast.error('Er ging iets mis bij het uitnodigen.')
        })
      }else if(this.type == 'program'){
        api.post(`${config.url.api}/v1/programs/create/invite`, this.dataprogram).then((res) => {
          if (res.data.success) {
            //redirect to the newly created recource.
            this.$toast.success('Uitnodiging succesvol aangemaakt.')
            this.$emit('close-dialog');
          } else {
            this.$toast.error('Er ging iets mis bij het uitnodigen.')
          }
        }).catch(() => {
          this.$toast.error('Er ging iets mis bij het uitnodigen.')
        })
      }
		},
		getProjects() {
			api.get(`${config.url.api}/v1/projects/list`).then((res) => {
				if (res.data.success) {
					this.projects = res.data.data;
				}
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van je projecten.')
						break;
				}
			})
		},
    getPrograms() {
			api.get(`${config.url.api}/v1/programs/list`).then((res) => {
				if (res.data.success) {
					this.programs = res.data.data;
				}
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error("Fout bij het ophalen van je programma's.")
						break;
				}
			})
		},
	},
	mounted() {
		this.getProjects();
    this.getPrograms();
	},
}

</script>

<style></style>