<template>
    <v-app v-if="ready" class="overflow-hidden">
        <topmenubar :breadcrumbs="breadcrumbs" />
        <v-container>
            <v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%"
                color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0 sidebar-drawer">
                <v-list flat light>
                    <v-list-item-group v-model="tab" light>
                        <v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
                            <v-list-item-title :key="i">
                                {{ item.step }} {{ item.title }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                    <v-spacer></v-spacer>
                </v-list>

            </v-navigation-drawer>
            <v-main class="pa-0 ml-1 pt-0 pa-15">

                <v-row v-if="type == 'accept'">
                    <v-col cols="12">
                        <h1 class="text-center font-weight-bold">Weet u zeker dat u de uitnodiging voor "{{ project.title
                        }}" wil
                            accepteren?</h1>
                        <p class="text-center font-weight-light my-10">Omschrijving van het project: {{ project.description
                        }}</p>
                        <p class="text-center mt-10">Selecteer hieronder of u de uitnodiging voor het project wil
                            accepteren.</p>
                    </v-col>
                    <v-col cols="6"> <v-btn @click="submit" color="primary"
                            class="ml-auto d-block">accepteren</v-btn></v-col>
                    <v-col cols="6"> <v-btn color="themewhite" class="mr-auto d-block">terug</v-btn></v-col>
                </v-row>
                <v-row v-if="type == 'decline'">
                    <v-col cols="12">
                        <h1 class="text-center font-weight-bold">Weet u zeker dat u de uitnodiging voor "{{ project.title
                        }}" wil
                            afwijzen?</h1>
                        <p class="text-center font-weight-light my-10">Omschrijving van het project: {{ project.description
                        }}</p>
                        <p class="text-center mt-10">Selecteer hieronder of u de uitnodiging voor het project wil
                            afwijzen.</p>
                    </v-col>
                    <v-col cols="6"> <v-btn @click="submit" color="primary" class="ml-auto d-block">afwijzen</v-btn></v-col>
                    <v-col cols="6"> <v-btn color="themewhite" class="mr-auto d-block">terug</v-btn></v-col>
                </v-row>
            </v-main>
        </v-container>
    </v-app>
</template>

<script>
import topmenubar from "@/components/topmenubar.vue";
import api from "../../services/api";
import config from '../../config';
export default {
    components: { topmenubar },
    data() {
        return {
            tab: 0,
            type: "accept",
            sidebar: true,
            ready: false,
            breadcrumbs: [],
            menuitems: [],
            project: {
                title: "Onbekend"
            }
        };
    },
    methods: {
        submit() {
            api.post(`${config.url.api}/v1/projects/${this.type}/invite/${this.$route.params.id}`).then((res) => {
                if (res.data.success) {
                    //redirect to the newly created recource.
                    switch (this.type) {
                        case 'accept':
                            this.$router.push("/project/" + res.data.slug)
                            this.$toast.success('Uitnodiging succesvol geaccepteerd.')
                            break;
                        default:
                            this.$router.push("/")
                            this.$toast.success('Uitnodiging succesvol afgewezen.')
                            break;
                    }

                } else {
                    this.$toast.error('Je hebt geen openstaande uitnodiging voor dit project.')
                }
            }).catch((err) => {
                switch (err.response.status) {
                    case 401:
                        this.$toast.error('Er ging iets mis bij het accepteren van de uitnodiging.')
                        this.$router.push("/")
                        break;
                    default:
                        this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
                        break;
                }
            })
        },
        getProject() {
            api.get(`${config.url.api}/v1/projects/${this.$route.params.id}`).then((res) => {
                this.project = res.data;
                this.ready = true;
            }).catch((err) => {
                switch (err.response.status) {
                    case 401:
                        this.$router.push("/pagina-niet-gevonden")
                        break;
                    case 404:
                        this.$router.push("/pagina-niet-gevonden")
                        break;
                    default:
                        this.$toast.error('Fout bij het ophalen van het project.')
                        break;
                }
            })
        }

    },
    mounted() {
        this.type = this.$route.params.subid == "accepteren" ? 'accept' : 'decline';
        this.getProject();
    },

};
</script>
<style>
body {
    background: var(--themegreen);
}

.footer-buttons {
    width: fit-content !important;
    right: 0 !important;
    left: unset !important;
}
</style>