<template>
  <v-app-bar class="app-bar" color="#ffffff" permanent app clipped-left height="100" elevation="0">

    <a class="app-title-logo text-truncate" @click="$router.push('/')"
      title=" Monitoring Transformatieopgave Jeugd - Versterken, verminderen & voorkomen ">
      <h3 class="text-truncate">
        Monitoring Transformatieopgave Jeugd
        <small class="text-truncate">Versterken, verminderen & voorkomen</small>
      </h3>
    </a>
    <v-breadcrumbs :items="this.breadcrumbs">
      <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.href">
        {{ item.text }}
      </v-breadcrumbs-item>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>

    <div class="logged-in" v-if="user.loggedin">



      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" text class="pt-5 pb-1" v-bind="attrs" v-on="on">
            <v-row>
              <v-col cols="10">
                <p color="themegray" class="w-auto text-btn">{{ welcome }} {{ user.firstname.capitalize() }}</p>
              </v-col>
              <v-col cols="2"><img v-if="!user.profilepicture" src="../assets/user-jh-icon.svg" alt="" /><img v-else
                  :src="avatarurl" alt="" /></v-col>
            </v-row>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="account">
            <v-list-item-title>
              Account instellingen
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>
              Uitloggen
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </div>
    <div class="logged-out" v-else>
      <v-btn text color="primary" :to="'/inloggen'">
        Log in <v-icon class="ml-3">fas fa-sign-in-alt</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import * as cookies from 'js-cookie'
import config from '../config'
export default {
  components: {},

  props: {
    breadcrumbs: Array,
    sidebar: Boolean,
  },
  computed: {
    ...mapGetters({ user: 'getuser' }),
    avatarurl() {
      return `${config.url.cdn}/usercontent/avatars/${this.user.profilepicture}.png`
    },
    welcome() {
      var currentTime = new Date().getHours();
      if (currentTime >= 5 && currentTime < 12) {
        return "Goedemorgen";
      } else if (currentTime >= 12 && currentTime < 18) {
        return "Goedemiddag";
      } else {
        return "Goedenavond";
      }
    }
  },
  methods: {
    account() {
      this.$router.push('/mijn-account')
    },
    logout() {
      cookies.remove('jh_access_token', { domain: config.cookiedomain, path: '/' });
      cookies.remove('jh_refresh_token', { domain: config.cookiedomain, path: '/' });
      cookies.remove('jh_user', { domain: config.cookiedomain, path: '/' });
      location.replace(config.url.main + '/inloggen');
    }
  },
  data: () => ({

    props: {
      title: Array,
    },
  }),
};
</script>

<style>
.text-btn {
    text-transform: none;
    font-family: "Roboto";
    color: #3f4a5e;
    letter-spacing: 0;
}

.text-btn:hover {
  cursor: pointer;
}

header {
  height: unset;
  padding: 0px 40px !important;
  background: #fff;
}

.app-title-logo {
  text-decoration: none;
}

.app-title-logo h3 {
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: #3f4a5e;
  font-family: "Roboto";
}

.app-title-logo small {
  font-size: 12px;
  opacity: 60%;
  display: block;
  font-weight: 400;
}

@media(max-width:768px) {
  header {
    padding: 0px 0px !important
  }

  .app-title-logo h3 {
    font-size: 14px;
  }
}
</style>
