<template>
    <div>
        <div>
            <div class="text-right">
                <v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
                    <v-icon>fas fa-times </v-icon>
                </v-btn>
            </div>
            <div class="text-center pb-5">
                <h3>Update uw project beschrijving</h3>
            </div>

            <v-textarea filled class="theme-input" solo label="Project beschrijving" auto-grow v-model="data.desc"></v-textarea>
        </div>
        <div class="text-center pt-5">
            <v-btn class="btn" color="themepurple" @click="submit" dark>Opslaan</v-btn>
        </div>
    </div>
</template>
<script>
import config from '../../../config';
import api from "../../../services/api";
export default {
    props: {
        value: String,
        project: Number,
    },
    data: () => ({
        data: {
            desc: "",
        }
    }),
    mounted() {
        this.data.desc = this.value
    },
    methods: {
        submit() {
            api.patch(`${config.url.api}/v1/projects/${this.project}`, this.data).then((res) => {
                if (res.data.success) {
                    this.$toast.success('Project beschrijving succesvol geupdate.')
                    this.$emit('updated')
                    this.$emit('close-dialog')
                } else {
                    this.$toast.error('Er ging iets mis bij het updaten van de beschrijving van je project.')
                }
            }).catch(() => {
                this.$toast.error('Er ging iets mis bij het updaten van de beschrijving van je project.')
            })
        }
    }
}

</script>

<style></style>