<template>
  <div>
    <Projectsidebarmenu :sidebar="sidebar" />


    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="ml-1 pt-0 pa-15">
      <v-row v-if="ready">
        <v-dialog v-model="editprojectdescriptiondialog" content-class="theme-popup">
          <Projectdescriptionedit :project="project.id" v-model="project.description"
            @close-dialog="editprojectdescriptiondialog = false" @updated="getProject()" />
        </v-dialog>
        <v-dialog v-model="editprojecttitledialog" content-class="theme-popup">
          <Projecttitleedit :project="project.id" v-model="project.title" @close-dialog="editprojecttitledialog = false"
            @updated="getProject()" />
        </v-dialog>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="11">
              <h1 class="mb-5">{{ project.title.capitalize() }}</h1>
            </v-col>
            <v-col cols="1">
              <v-btn text elevation="0" @click="editprojecttitledialog = true"> <v-icon color="primary" small>
                  mdi-pencil
                </v-icon></v-btn>
            </v-col>
            <v-col cols="11">
              <p class="text-gray">
                {{ project.description }}
              </p>
            </v-col>
            <v-col cols="1">
              <v-btn text elevation="0" @click="editprojectdescriptiondialog = true"> <v-icon color="primary" small>
                  mdi-pencil
                </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4" class="text-gray">
              Gestart: {{ new Date(project.created_at * 1000).toLocaleDateString("nl-NL", {
                timeZone: "Europe/Amsterdam"
              }) }}
            </v-col>
            <v-col cols="12" md="4" class="text-gray">
              Laatst gewijzigd: {{ new Date(project.updated_at * 1000).toLocaleDateString("nl-NL", {
                timeZone:
                  "Europe/Amsterdam"
              }) }}
            </v-col>
            <v-col cols="12" md="4" class="text-gray">
              <p v-if="project.owner.name != false"> Door: {{ project.owner.name.firstname.capitalize() }} {{
                project.owner.name.lastname.capitalize() }}</p>
              <p v-else>Door: Onbekend</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" class="text-right ml-auto my-auto">
          <v-btn @click="startcountingdialog = true" class="text-white btn" color="themepurple" disabled>
            <v-icon left>fas fa-file</v-icon> Project Downloaden als PDF</v-btn>
        </v-col>
        <v-col cols="12">
          <h2>Mensen die aan dit project werken</h2>
        </v-col>
        <v-col cols="12">
          <v-list v-if="readyusers">
            <v-list-item dense v-for="user in project.users" :key="user.id" class="px-0">
              <v-list-item-content>
                <p class="my-0">
                  <strong>{{
                    user.fullname.capitalize() + " - " + user.role.getrole()
                  }}</strong>

                </p>

              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-skeleton-loader v-else type="list-item"></v-skeleton-loader>

          <v-btn elevation="0" @click="inviteuserdialog = !inviteuserdialog" text class="px-0 py-0 text-primary text-none text-subtitle-2">Een collega in het project
            uitnodigen</v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-skeleton-loader type="table-heading, list-item-two-line"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-footer class="ml-2 pa-10" color="transparent" fixed>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn class="mr-3 btn" outlined to="/" color="themepurple">Terug</v-btn>
            <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
    <!-- Project invite dialog -->
    <invitedialog :dialog="inviteuserdialog" @close-dialog="inviteuserdialog = false" />

  </div>
</template>

<script>

import Projectsidebarmenu from '@/components/projectsidebarmenu.vue';
import Projectdescriptionedit from '@/components/dialogs/project/editdescription.vue';
import Projecttitleedit from '@/components/dialogs/project/edittitle.vue';
import invitedialog from "@/components/dialogs/project/invite.vue";


import api from "../../services/api";
import config from '../../config';
export default {
  components: { Projectsidebarmenu, Projectdescriptionedit, Projecttitleedit, invitedialog },

  data() {
    return {
      inviteuserdialog: false,
      sidebar: true,
      ready: false,
      readyusers: false,
      nextdialog: false,
      editprojectdescriptiondialog: false,
      editprojecttitledialog: false,
      startcountingdialog: false,
      senddata: false,
      project: {
        title: "Onbekend",
        description: "Onbekend",
        users: []
      },
      tab: 0,
    };
  },
  mounted() {
    this.getProject();
  },
  methods: {
    getProjectUsers(id) {
      this.readyusers = false;
      api.get(`${config.url.api}/v1/projects/${id}/users`).then((res) => {
        this.project.users = res.data.data;
        this.readyusers = true;
      })
    },
    getProject() {
      this.ready = false;
      api.get(`${config.url.api}/v1/projects/${this.$route.params.id}`).then((res) => {
        this.project = res.data;
        this.getProjectUsers(this.project.id);
        this.ready = true;
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van het project.')
            break;
        }
      })
    },
    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
};

</script>
<style>
body {
  transition: all ease-in-out 0.2s;
  background-color: #e8f4f6;
}
</style>
