<template>
    <div>
        <div>
            <div class="text-right">
                <v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
                    <v-icon>fas fa-times </v-icon>
                </v-btn>
            </div>
            <div class="text-center pb-5">
                <h3>Update uw projectnaam</h3>
            </div>

            <label for="">Projectnaam</label>
            <v-text-field class="theme-input" solo title="Project naam" v-model="data.title"></v-text-field>
        </div>
        <div class="text-center pt-5">
            <v-btn class="btn" color="themepurple" @click="submit" dark>Opslaan</v-btn>
        </div>
    </div>
</template>
<script>
import config from '../../../config';
import api from "../../../services/api";
export default {
    props: {
        dialog: Boolean,
        value: String,
        project: Number,
    },
    data: () => ({
        data: {
            title: "",
        }
    }),
    mounted() {
        this.data.title = this.value;
    },
    methods: {
        submit() {
            api.patch(`${config.url.api}/v1/projects/${this.project}`, this.data).then((res) => {
                if (res.data.success) {
                    this.$toast.success('Projectnaam succesvol geupdate.')
                    this.$emit('updated')
                    this.$emit('close-dialog')
                } else {
                    this.$toast.error('Er ging iets mis bij het updaten van de titel van je project.')
                }
            }).catch(() => {
                this.$toast.error('Er ging iets mis bij het updaten van de titel van je project.')
            })
        }
    }
}

</script>

<style></style>